import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Showcard = ({ profile }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        maxWidth: 200, // Set a max width to keep the card compact
        boxShadow: theme.shadows[2],
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer', // Makes it clear the card is clickable
      }}
      onClick={() => navigate(`/${profile.user.username}`)}
    >
      <CardMedia
        component="img"
        height="100"
        image={profile.image || '/images/PICA.jpg'} // Use a default image if none exists
        alt={`${profile.user.username}'s profile`}
        sx={{ borderRadius: '50%', width: 80, height: 80, margin: '16px auto 0' }} // Center and style as a circle
      />
      <CardContent sx={{ textAlign: 'center', padding: theme.spacing(1) }}>
        <Typography variant="subtitle2" gutterBottom>
          {profile.user.username}
        </Typography>
        <Typography variant="caption" color="textSecondary" noWrap>
          {profile.about_me}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', padding: theme.spacing(1) }}>
        <Button size="small" color="primary" onClick={() => navigate(`/${profile.user.username}`)}>
          View Profile
        </Button>
      </CardActions>
    </Card>
  );
};

export default Showcard;
