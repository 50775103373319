import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from './../context/ApiInstance';
import { TextField, Button, Typography, Container } from '@mui/material';

const PasswordResetConfirmPage = () => {
  const { uidb64, token } = useParams(); // Get uid and token from URL parameters
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await api.post(
        `profile/password_reset_confirm/${uidb64}/${token}/`, // Include uidb64 and token in URL
        { new_password: newPassword }
      );
      setSuccess(true);
      navigate('/login'); // Redirect to login after successful reset
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" component="h1" align="center" gutterBottom>
        Reset Password
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success ? (
        <Typography color="primary">Password has been reset successfully!</Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Confirm New Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Reset Password
          </Button>
        </form>
      )}
    </Container>
  );
};

export default PasswordResetConfirmPage;
