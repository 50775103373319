import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import api from '../../context/ApiInstance';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { Typography, Grid } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const Input = styled('input')({
  display: 'none',
});

const UserSettings = () => {
  const { authTokens, logoutUser, user } = useContext(AuthContext);
  const [settings, setSettings] = useState({
    bio: '',
    about_me: '',
    image: null,
    image_titel: null,
  });
  const [preview, setPreview] = useState({
    image: null,
    image_titel: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [password, setPassword] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false); 
  const navigate = useNavigate();

  // Fetch profile settings on component load
  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        api.interceptors.request.use((config) => {
          if (authTokens) {
            config.headers.Authorization = `Bearer ${authTokens.access}`;
          }
          return config;
        });

        const response = await api.get('profile/settings/');
        setSettings(response.data);
        setPreview({
          image: response.data.image,
          image_titel: response.data.image_titel,
        });
      } catch (error) {
        setApiError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, [authTokens]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  // Handle file upload changes
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setSettings({ ...settings, [name]: files[0] });
      setPreview({ ...preview, [name]: URL.createObjectURL(files[0]) });
    }
  };

  // Submit updated profile information
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('bio', settings.bio);
    formData.append('about_me', settings.about_me);
    if (settings.image instanceof File) {
      formData.append('image', settings.image);
    }
    if (settings.image_titel instanceof File) {
      formData.append('image_titel', settings.image_titel);
    }

    try {
      const response = await api.put('profile/settings/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSettings(response.data);
      setPreview({
        image: response.data.image,
        image_titel: response.data.image_titel,
      });
      navigate(`/${user.username}`);
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete account after password confirmation
  const handleDeleteAccount = () => {
    setShowPasswordInput(true);
  };

  const handleConfirmDelete = async () => {
    if (!password) {
      setApiError({ message: "Password is required to delete your account." });
      return;
    }

    setIsLoading(true);
    try {
      await api.delete('profile/delete_account/', {
        data: { password },
        headers: {
          Authorization: `Bearer ${authTokens.access}`
        }
      });
      logoutUser();
      navigate('/');
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle image deletion
  const handleDeleteImage = async (imageType) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append(imageType, true);  // Append the image type to delete
  
      await api.delete('profile/settings/', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authTokens.access}`,
        },
        data: formData,
      });
      setPreview({ ...preview, [imageType]: null });
      setSettings({ ...settings, [imageType]: null });
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={4} flexDirection={{ xs: 'column', sm: 'row' }}>
        <Avatar
          alt={settings.username}
          src={preview.image || '/static/images/avatar/1.jpg'}
          sx={{ width: 150, height: 150, mb: { xs: 2, sm: 0 } }}
        />
        <Typography variant="h5" component="h1" sx={{ ml: { sm: 2 }, textAlign: { xs: 'center', sm: 'left' } }}>
          {settings.username}
        </Typography>
      </Box>

      {isLoading && <Typography>Loading...</Typography>}
      {apiError && <Typography color="error">Error: {apiError.message}</Typography>}

      <form onSubmit={handleSubmit}>
        <TextField
          label="Bio"
          name="bio"
          value={settings.bio}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="About Me (optional, 30 characters max)"
          name="about_me"
          value={settings.about_me}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />

        <Box display="flex" justifyContent="center" mt={2} mb={4} flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
          <label htmlFor="image">
            <Input accept="image/*" id="image" name="image" type="file" onChange={handleFileChange} />
            <Button variant="outlined" component="span" sx={{ mr: { sm: 2 }, mb: { xs: 2, sm: 0 }, display: 'flex', alignItems: 'center' }} startIcon={<UploadFileIcon />}>
              Upload Profile Image
            </Button>
          </label>

          <label htmlFor="image_titel">
            <Input accept="image/*" id="image_titel" name="image_titel" type="file" onChange={handleFileChange} />
            <Button variant="outlined" component="span" sx={{ display: 'flex', alignItems: 'center' }} startIcon={<UploadFileIcon />}>
              Upload Background Image
            </Button>
          </label>
        </Box>

        <Grid>
          {preview.image && (
            <Box mt={2}>
              <Button fullWidth variant="outlined" color="error" onClick={() => handleDeleteImage('image')}>
                Delete Profile Image
              </Button>
            </Box>
          )}

          {preview.image_titel && (
            <Box mt={2}>
              <Button fullWidth variant="outlined" color="error" onClick={() => handleDeleteImage('image_titel')}>
                Delete Background Image
              </Button>
            </Box>
          )}
        </Grid>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Save
            </Button>
          </Grid>
          {showPasswordInput ? (
            <>
              <TextField
                label="Password"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Grid item xs={12} sm={6}>
                <Button fullWidth variant="contained" color="secondary" onClick={handleConfirmDelete}>
                  Confirm Delete Account
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={6}>
              <Button fullWidth variant="contained" color="secondary" onClick={handleDeleteAccount}>
                Delete Account
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default UserSettings;
