import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, CircularProgress, Typography, Box, Grid, FormControlLabel, Switch ,Stack} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AuthContext from '../context/AuthContext';
import LinkCard from '../components/Links';
import api from '../context/ApiInstance';

const Feed = () => {
  const theme = useTheme();
  const { authTokens } = useContext(AuthContext);
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterFollowing, setFilterFollowing] = useState(true);  // Default to followers only

  const toggleFilterFollowing = () => {
    setFilterFollowing((prev) => !prev);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchLinks = async () => {
      setIsLoading(true);
      setApiError(null);

      try {
        const url = `content/explorer/?page=${currentPage}&filter_following=${filterFollowing}`;
        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${authTokens?.access}`,
          },
        });

        if (response.status !== 200) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        const linksData = response.data;
        setLinks(linksData.links || []);
        setTotalPages(Math.ceil((linksData.count || 0) / 10));
      } catch (error) {
        setApiError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLinks();
  }, [currentPage, filterFollowing, authTokens]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const groupLinksByUser = (links) => {
    return links.reduce((grouped, link) => {
      if (!grouped[link.user]) {
        grouped[link.user] = [];
      }
      grouped[link.user].push(link);
      return grouped;
    }, {});
  };

  return (
    <Container sx={{ mt: theme.spacing(4) }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={filterFollowing} onChange={toggleFilterFollowing} color="primary" />}
            label={filterFollowing ? 'Followers Only' : 'All Users'}
            sx={{ display: 'flex', justifyContent: 'center', my: 2 }}
          />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} md={8}>
          <Container>
            {isLoading ? (
              <Box display="flex" justifyContent="center" my={4}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              Object.entries(groupLinksByUser(links)).map(([user, userLinks], index) => (
                <Box
                  key={index}
                  my={4}
                  sx={{
                    padding: theme.spacing(2),
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows[3],
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <Typography variant="h6" gutterBottom color={theme.palette.text.primary}>
                    {user}
                  </Typography>
                  <Stack spacing={2}>
                    {userLinks.map((link) => (
                      <LinkCard key={link.id} data={link} />
                    ))}
                  </Stack>
                </Box>
              ))
            )}
          </Container>
        </Grid>

        {/* Pagination */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" my={4}>
            <Button onClick={handlePreviousPage} disabled={currentPage === 1} variant="contained" color="primary">
              Previous
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              variant="contained"
              color="primary"
              sx={{ ml: theme.spacing(2) }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Error Handling */}
      {apiError && (
        <Box mt={2} sx={{ color: theme.palette.error.main }}>
          <Typography color="error" align="center">
            {apiError.message}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Feed;
