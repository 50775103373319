// src/pages/EmailVerificationPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import api from '../context/ApiInstance';

const EmailVerificationPage = () => {
  const { token } = useParams();
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await api.get(`/verify-email/${token}/`);
        setMessage(response.data.message);
      } catch (err) {
        setError(true);
        setMessage(
          err.response?.data?.message || 'An error occurred while verifying your email.'
        );
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p style={{ color: error ? 'red' : 'green' }}>{message}</p>
    </div>
  );
};

export default EmailVerificationPage;
