// Explorerpage.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, CircularProgress, Typography, Box, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AuthContext from '../context/AuthContext';
import api from '../context/ApiInstance';
import ShowCard from '../components/ShowCard'; 

const Explorerpage = () => {
  const theme = useTheme();
  const { authTokens } = useContext(AuthContext);
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchProfiles = async () => {
      setIsLoading(true);
      setApiError(null);

      try {
        const response = await api.get('/profile/explore/', {
          headers: {
            Authorization: `Bearer ${authTokens?.access}`,
          },
        });

        if (response.status !== 200) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        setProfiles(response.data);
      } catch (error) {
        setApiError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfiles();
  }, [authTokens]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 6, 0)); // Show 6 profiles at a time
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 6, profiles.length - 6));
  };

  return (
    <Container sx={{ mt: theme.spacing(4) }}>
      <Typography variant="h4" align="center" gutterBottom>
        Explore Profiles
      </Typography>

      {isLoading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress color="primary" />
        </Box>
      ) : apiError ? (
        <Box mt={2} sx={{ color: theme.palette.error.main }}>
          <Typography color="error" align="center">
            {apiError.message}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2} justifyContent="center">
            {profiles.slice(currentIndex, currentIndex + 6).map((profile, index) => (
              <Grid item xs={6} sm={4} md={2} key={index}> {/* Adjusted for smaller profile cards */}
                <ShowCard profile={profile} />
              </Grid>
            ))}
          </Grid>

          {/* Navigation buttons */}
          <Box display="flex" justifyContent="center" mt={4}>
            <Button onClick={handlePrevious} disabled={currentIndex === 0} variant="contained" color="primary">
              Previous
            </Button>
            <Button
              onClick={handleNext}
              disabled={currentIndex >= profiles.length - 6}
              variant="contained"
              color="primary"
              sx={{ ml: theme.spacing(2) }}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Explorerpage;
