import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>

        <Typography variant="body1" gutterBottom>
          Welcome to [Your App Name]! By using this website and our services, you agree to comply with and be bound by the following terms and conditions of use. Please review them carefully. If you do not agree with these terms, you should not use this website or the services provided.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" gutterBottom>
          By accessing and using [Your App Name] services, you accept and agree to be bound by the terms and provisions of this agreement. In addition, when using these services, you are subject to any posted guidelines or rules applicable to such services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Modification of Terms
        </Typography>
        <Typography variant="body1" gutterBottom>
          [Your App Name] reserves the right to modify these terms at any time. Any changes will be posted on this page, and continued use of the service after such modifications will constitute your acknowledgment and acceptance of the modified terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. User Responsibilities
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree to use the service only for lawful purposes and in a way that does not infringe on the rights of, restrict, or inhibit anyone else's use and enjoyment of the service.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Termination
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" gutterBottom>
          In no event shall [Your App Name], its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Governing Law
        </Typography>
        <Typography variant="body1" gutterBottom>
          These terms shall be governed and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions about these Terms, please contact us at [your email or contact information].
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
