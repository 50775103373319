import React, { useState, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Box, Toolbar, IconButton, Typography, MenuItem, Menu } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { useTheme } from '@mui/material/styles';

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const { user, logoutUser } = useContext(AuthContext);
  const inputRef = useRef(null);
  
  const theme = useTheme();  // Using the custom theme

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user ? (
        [
          <MenuItem key="my-account" onClick={() => { handleMenuClose(); navigate(`/profile/${user.username}`); }}>Settings</MenuItem>,
          <MenuItem key="explorer" onClick={() => { handleMenuClose(); navigate('/explorer'); }}>Explorer</MenuItem>,
          <MenuItem key="feed" onClick={() => { handleMenuClose(); navigate('/feed'); }}>Feed</MenuItem>,
          <MenuItem key="logout" onClick={() => { logoutUser(); navigate('/login'); }}>Logout</MenuItem>
        ]
      ) : (
        [
          <MenuItem key="login" onClick={() => { handleMenuClose(); navigate('/login'); }}>Login</MenuItem>,
          <MenuItem key="register" onClick={() => { handleMenuClose(); navigate('/register'); }}>Register</MenuItem>,
          <MenuItem key="explorer" onClick={() => { handleMenuClose(); navigate('/explorer'); }}>Explorer</MenuItem>
        ]
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{
        backgroundColor: theme.palette.background.paper, // Apply background color from the theme
        color: theme.palette.text.secondary, // Apply text color from the theme
        borderRadius: '16px', // Match the theme's card border radius

      }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>

          {/* Logo */}
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{ 
              display: { xs: 'block', sm: 'block' }, 
              fontWeight: 'bold',
              color: theme.palette.primary.main, // Use primary color from theme for logo
              textDecoration: 'none' // Remove underline for the link
            }}
          >
            UDots
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          {/* Search and Profile Menu */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchIcon
              onClick={() => { navigate('/search'); }}
              sx={{ cursor: 'pointer', marginRight: 2, color: theme.palette.text.primary }} // Match text color
            />

            {user ? (
               <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                 <AddIcon 
                   onClick={() => { navigate('/app/createlink'); }} 
                   sx={{ cursor: 'pointer', color: theme.palette.text.primary }} // Match text color
                 />
                 <IconButton
                   size="large"
                   edge="end"
                   aria-label="account of current user"
                   aria-controls={menuId}
                   aria-haspopup="true"
                   onClick={() => { navigate(`/${user.username}`); }}
                   color="inherit"
                 >
                   <AccountCircle />
                 </IconButton>
                 <MoreVertIcon 
                   onClick={handleProfileMenuOpen} 
                   aria-controls={menuId} 
                   aria-haspopup="true" 
                   sx={{ cursor: 'pointer', color: theme.palette.text.primary }} // Match text color
                 />
              </Box>
            ) : (
              <>
                <MenuItem onClick={() => { navigate('/login'); }} sx={{ color: theme.palette.text.primary }}>
                  Login
                </MenuItem>
                <MenuItem onClick={() => { navigate('/register'); }} sx={{ color: theme.palette.text.primary }}>
                  Register
                </MenuItem>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}
