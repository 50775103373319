import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../context/ApiInstance';
import AuthContext from '../../context/AuthContext';
import ProfilCard from '../../components/ProfilCard';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinkCard from '../../components/Links';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import CreateLink from '../../components/CreateLink';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save'; 
import { useTheme, useMediaQuery } from '@mui/material';


import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const UserProfilePage = () => {
  // Context and state management
  const theme = useTheme();
  const { authTokens, user } = useContext(AuthContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { username } = useParams();
  const [profile, setProfile] = useState(null);
  const [links, setLinks] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editLinkData, setEditLinkData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // DnD sensors setup
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Handle drag end event
  const handleDragEnd = async (event) => {
    if (!isEditMode) return;
  
    const { active, over } = event;
  
    // Check if 'over' is null
    if (!over) {
      console.error('Drag ended outside of a valid droppable area');
      return;
    }
  
    // Check if the active and over items are the same
    if (active.id !== over.id) {
      const oldIndex = links.findIndex((link) => link.id === active.id);
      const newIndex = links.findIndex((link) => link.id === over.id);
  
      // Reorder the links array
      const newLinks = arrayMove(links, oldIndex, newIndex);
      setLinks(newLinks);
  
      // Prepare the payload for the backend
      const orderPayload = newLinks.map((link, index) => ({
        id: link.id,
        order: index + 1, // Assuming 1-based indexing
      }));
  
      // Send the updated order to the backend
      try {
        await api.post('/profile/update-order/', orderPayload);
      } catch (error) {
        console.error('Failed to update link order:', error);
        // Optionally, revert the order in case of failure
        setLinks(links);
      }
    }
  };

  // Check if the logged-in user owns the profile
  const isProfileOwner = user && user.username === username;

  // Fetch profile and links from the API
  const fetchProfile = async () => {
    setIsLoading(true);
    setApiError(null);
    try {
      const profileResponse = await api.get(`profile/user/${username}/`);
      setProfile(profileResponse.data);
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLinks = async () => {
    setIsLoading(true);
    setApiError(null);
    try {
      const response = await api.get(`profile/user/${username}/links/`, {
        params: { tag: selectedTag || '', page: currentPage },
      });
      const linksData = response.data;
      setLinks(linksData.links || []);
      setTags(linksData.tags || []);
      setTotalPages(Math.ceil(linksData.count / 10) || 1);
    } catch (error) {
      setApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle link deletion
  const handleDelete = async (linkId) => {
    try {
      await api.delete(`profile/user/${username}/links/${linkId}/`);
      setLinks((prevLinks) => prevLinks.filter((link) => link.id !== linkId));
    } catch (error) {
      setApiError(error);
    }
  };

  // Handle link save (either creating or updating a link)
  const handleSave = (savedLink) => {
    setLinks((prevLinks) =>
      editLinkData
        ? prevLinks.map((link) => (link.id === savedLink.id ? savedLink : link))
        : [savedLink, ...prevLinks]
    );
    closeEditModal();
  };

  // Open and close the edit modal
  const openEditModal = (link) => {
    setEditLinkData(link);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditLinkData(null);
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode((prev) => !prev);
  };

  // Handle tag click to filter links
  const handleClickTag = (tag) => {
    setSelectedTag(tag);
    setCurrentPage(1);
  };

  // Handle pagination
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Fetch profile and links when component mounts or dependencies change
  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use((config) => {
      if (authTokens) {
        config.headers.Authorization = `Bearer ${authTokens.access}`;
      }
      return config;
    });

    fetchProfile();
    fetchLinks();

    return () => {
      api.interceptors.request.eject(requestInterceptor);
    };
  }, [authTokens, username, selectedTag, currentPage]);

  return (
    <Box
    sx={{
      backgroundImage: profile?.image_titel ? `url(${profile.image_titel})` : 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      padding: 4,
      paddingLeft: isMobile ? 0 : 4, // Set left padding to 0 on mobile
      paddingRight: isMobile ? 0 : 4, // Set right padding to 0 on mobile
      backgroundColor: 'background.default', // Use theme background color
    }}
  >
 <Container maxWidth="lg" sx={{ padding: isMobile ? 0 : 4 }}> 
      <Box sx={{ marginBottom: 4 }}>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={12} md={4}>
            {profile && <ProfilCard data={profile} />}
            <Box display="flex" justifyContent="center" marginY={2}>
              <Stack direction="row" spacing={1}>
                {isProfileOwner && (
                  <EditIcon 
                    onClick={toggleEditMode}
                    sx={{
                      color: 'primary.main', // Use theme primary color for icons
                      cursor: 'pointer',
                    }}
                  >
                    {isEditMode ? <SaveIcon /> : <EditIcon />}  {/* Conditional rendering based on the state */}
                  </EditIcon>
                )}
                <FilterAltOffIcon
                
                  sx={{ 
                    
                    cursor: 'pointer', 
                    color: 'primary.main', // Use theme primary color for icons
                  }}
                  onClick={() => handleClickTag(null)}
                  label="Clear"
                />
                {tags.map((tag) => (
                  <Chip
                    variant="outlined"
                    sx={{ 
                      color: 'text.secondary', 
                      borderColor: 'primary.main',
                      backgroundColor: 'background.paper',
                    }}
                    key={tag.slug}
                    onClick={() => handleClickTag(tag.slug)}
                    label={tag.name}
                  />
                ))}
              </Stack>
            </Box>
            {apiError && (
              <Typography color="error">
                Error: {apiError.message}
              </Typography>
            )}
  
            {/* DnD Context and Sortable List */}
            {isEditMode ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext 
                  items={links} 
                  strategy={verticalListSortingStrategy}
                >
                  {links.length > 0 ? (
                    links.map((link) => (
                      <LinkCard
                        key={link.order}
                        id={link.id}
                        data={link}
                        onTagClick={handleClickTag}
                        onEdit={openEditModal}
                        onDelete={handleDelete}
                        isEditMode={isEditMode}
                      />
                    ))
                  ) : (
                    <Typography>No links available</Typography>
                  )}
                </SortableContext>
              </DndContext>
            ) : (
              links.length > 0 ? (
                links.map((link) => (
                  <LinkCard
                    key={link.order}
                    id={link.id}
                    data={link}
                    onTagClick={handleClickTag}
                    onEdit={openEditModal}
                    onDelete={handleDelete}
                    isEditMode={isEditMode}
                  />
                ))
              ) : (
                <Typography>No links available</Typography>
              )
            )}
  
            <Box display="flex" justifyContent="center" marginY={2}>
              <Pagination 
                count={totalPages} 
                page={currentPage} 
                onChange={handlePageChange} 
                sx={{
                  color: 'primary.main', // Use theme primary color for pagination
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  
    {/* Edit/Create Link Dialog */}
    <Dialog open={isEditModalOpen} onClose={closeEditModal} maxWidth="sm" fullWidth>
      <DialogTitle>{editLinkData ? 'Edit Link' : 'Create Link'}</DialogTitle>
      <DialogContent>
        <CreateLink
          initialData={editLinkData}
          onSave={handleSave}
          onClose={closeEditModal}
        />
      </DialogContent>
    </Dialog>
     
  </Box>



  
  );

}
export default UserProfilePage;
