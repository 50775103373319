import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import api from '../context/ApiInstance';
import { styled, alpha } from '@mui/material/styles';
import { Container, Typography, List, ListItem, ListItemText, TextField, Box, Avatar } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ResultPage() {
  const query = useQuery().get('query');
  const [searchQuery, setSearchQuery] = useState(query || '');
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const fetchResults = useCallback(
    debounce(async (searchQuery) => {
      try {
        const response = await api.get(`profile/search/?search=${searchQuery}`);
        setResults(response.data.results);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (query) {
      fetchResults(query);
    }
  }, [query, fetchResults]);

  const handleInputChange = (event, newInputValue) => {
    setSearchQuery(newInputValue);
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      navigate(`/search?query=${searchQuery}`);
    }
  };

  const manipulatedResults = results.map((option) => ({
    label: option.user.username || '',
  }));

  return (
    <StyledContainer>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Search>
          <Autocomplete
            freeSolo
            disableClearable
            inputValue={searchQuery}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            getOptionLabel={(option) => option.label || ''}
            options={manipulatedResults}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: { xs: '100%', sm: 500 }, bgcolor: 'background.paper' }}
                label="Search input"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
        </Search>
      </Box>

      {results.length > 0 ? (
        <List sx={{ width: '100%', maxWidth: 500, margin: '0 auto' }}>
          {results.map((result, index) => (
            <ListItem key={index} component={Link} to={`/${result.user.username}`} button>
              <Avatar alt={result.user.username} src={result.image} sx={{ marginRight: 2, bgcolor: 'primary.main' }} />
              <ListItemText primary={result.user.username} secondary={result.about_me} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 4 }}>
          No results found.
        </Typography>
      )}
    </StyledContainer>
  );
}
