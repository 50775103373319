import React, { useState, useEffect, useContext } from 'react';
import BASEURL from '../context/ApiEndpoints';
import AuthContext from '../context/AuthContext';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { useParams } from 'react-router-dom';

const CreateLink = ({ initialData = null, onSave }) => {
    const { authTokens, user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({});
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (initialData) {
            setTags(initialData.tags || []);
        }
    }, [initialData]);

    const handleTagDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleTagAddition = (tag) => {
        setTags([...tags, tag.text]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setApiError(null);
        setFieldErrors({});
    
        try {
            // Create a JSON object with form data
            const jsonData = {
                title: e.target.title.value,
                description: e.target.description.value,
                url: e.target.url.value,
                tags: tags.length > 0 ? tags : "" // Use an empty string if no tags
            };
    
            const url = initialData
                ? `${BASEURL}content/post/${initialData.id}/`
                : `${BASEURL}content/post/`;
            const method = initialData ? 'PUT' : 'POST';
    
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json', // Set content type to JSON
                    'Authorization': 'Bearer ' + authTokens?.access,
                },
                body: JSON.stringify(jsonData) // Convert the data to JSON string
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.detail) {
                    throw new Error(errorData.detail);
                } else {
                    setFieldErrors(errorData);
                }
                throw new Error(`API request failed with status ${response.status}`);
            }
    
            const responseData = await response.json();
            console.log('Posted data:', responseData);
            navigate(`/${user.username}`); // Navigate after successful submission
        } catch (error) {
            setApiError(error);
        } finally {
            setIsLoading(false);
        }
    };
    
    

    return (
<Container
    sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: 'background.default', // Add background color based on the theme
        padding: { xs: 2, md: 4 },
    }}
>
    {isLoading && <Typography color="text.secondary">Loading...</Typography>}
    {apiError && <Typography color="error">Error: {apiError.message}</Typography>}

    <Box
        sx={{
            maxWidth: { xs: '100%', sm: '500px' },
            width: '100%',
            padding: { xs: 3, sm: 5 },
            bgcolor: 'background.paper', // Adjust background color based on the theme
            borderRadius: 3, // Smoother border radius
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle box shadow
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
        }}
    >
        {/* Close (X) Button */}
        <IconButton
            onClick={() => navigate(`/${user.username}`)}
            sx={{ position: 'absolute', top: 16, right: 16, color: 'text.primary' }}
        >
            <CloseIcon />
        </IconButton>

        <Typography component="h1" variant="h5" color="text.primary" sx={{ mb: 3 }}>
            {initialData ? 'Edit Link' : 'Post Link'}
        </Typography>

        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2, // Adds space between form elements
            }}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                defaultValue={initialData ? initialData.title : ''}
                error={Boolean(fieldErrors.title)}
                helperText={fieldErrors.title}
                variant="outlined"
                InputProps={{
                    sx: { color: 'text.primary' },
                }}
                sx={{ bgcolor: 'background.paper' }}
            />
            <TextField
                margin="normal"
                fullWidth
                name="description"
                label="Description"
                type="text"
                id="description"
                defaultValue={initialData ? initialData.description : ''}
                error={Boolean(fieldErrors.description)}
                helperText={fieldErrors.description}
                variant="outlined"
                InputProps={{
                    sx: { color: 'text.primary' },
                }}
                sx={{ bgcolor: 'background.paper' }}
            />

            <TextField
                required
                fullWidth
                name="url"
                label="URL"
                type="text"
                id="url"
                defaultValue={initialData ? initialData.url : ''}
                error={Boolean(fieldErrors.url)}
                helperText={fieldErrors.url}
                variant="outlined"
                InputProps={{
                    sx: { color: 'text.primary' },
                }}
                sx={{ bgcolor: 'background.paper' }}
            />

            <ReactTags
                tags={tags.map((tag, index) => ({ id: tag, text: tag }))}
                handleDelete={handleTagDelete}
                handleAddition={handleTagAddition}
                placeholder="Add tags (optional)"
            />
            {fieldErrors.tags && <Typography color="error">{fieldErrors.tags}</Typography>}

            <input
                type="file"
                accept="image/jpeg,image/png,image/gif"
                name="image"
                id="image"
                style={{ display: 'none' }}
            />
            <label htmlFor="image">
                <Button component="span" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Upload Image
                </Button>
            </label>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
            >
                {initialData ? 'Save Changes' : 'Post'}
            </Button>
        </Box>
    </Box>
</Container>

    );
};

export default CreateLink;
